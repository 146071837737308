<template>
  <div id="app1" class="app-container">
    <div class="content">
      <div class="detail">
        <div class="box">
          <span class="label">门店名称：</span>
          <div class="text">{{ form.shopName }}</div>
        </div>
        <div class="box">
          <span class="label">盒子Mac-ID：</span>
          <div class="text">{{ form.MAC }}</div>
        </div>
      </div>
      <div class="live">
        <video id="videoElement" autoplay width="800" height="600">
          <source type="application/x-mpegURL" />
        </video>
      </div>
    </div>
  </div>
</template>

<script>
import flvjs from 'flv.js/dist/flv.js'

export default {
  data() {
    return {
      headMenuTitle: '',
      form: {},
    }
  },
  methods: {
    handleVideo(src) {
      if (flvjs.isSupported()) {
        var videoElement = document.getElementById('videoElement')
        var flvPlayer = flvjs.createPlayer({
          type: 'flv',
          url: src,
        })
        flvPlayer.attachMediaElement(videoElement)
        flvPlayer.load()
        flvPlayer.play()
      }
    },
    goBack() {
      history.go(-1)
    },
  },
  created() {},
  computed: {},
  mounted() {
    let flag = this.$route.query.flag
    this.form = JSON.parse(sessionStorage.getItem('ADBoxInfoObj'))
    this.headMenuTitle = +flag ? '查看摄像头视频' : '查看录屏视频'
    let videoSrc = +flag ? this.form.cameraLiveUrl : this.form.screenLiveUrl
    console.log(videoSrc)
    this.handleVideo(videoSrc)
  },
}
</script>

<style lang="less" scoped>
.containerX .contentX {
  padding: 61px 0 40px;
}
.headmenu {
  padding: 0 20px;
  height: 70px;
  line-height: 70px;
  font-size: 14px;
  border-bottom: 1px solid #d1d1d1;
}

.headmenu a {
  color: #5c76cc;
}

.content {
  padding: 0 20px 20px;
}
.content .detail {
  padding: 10px 0;
  display: flex;
  width: 100%;
  font-size: 14px;
  /*justify-content: space-between;*/
  border-bottom: 1px solid #d1d1d1;
}
.content .detail .box {
  display: flex;
  align-items: center;
  width: 20%;
}

.title {
  font-size: 16px;
  font-weight: 700;
}

.vjs-error-display.vjs-modal-dialog {
  display: none;
}
</style>